<div class="creative-3">
    <section class="coming-soon-area">
        <div class="container-fluid p-0">
            <div class="row m-0">
                <div class="col-lg-6 col-md-12 p-0">
                    <div class="coming-soon-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="logo">
                                    <img src="assets/img/PowerHour-Logo.svg" width="90%" alt="logo">
                                </div>
                                <div id="timer">
                                    <div id="days">
                                        {{days}} <span>Tage</span>
                                    </div>
                                    <div id="hours">
                                        {{hours}} <span>Stunden</span>
                                    </div>
                                    <div id="minutes">
                                        {{minutes}} <span>Minuten</span>
                                    </div>
                                    <div id="seconds">
                                        {{seconds}} <span>Sekunden</span>
                                    </div>
                                </div>
                                <h1>Unsere Power Hour App kommt bald!</h1>
                                <p>Schon bald können Sie hier digital die Power Hour durchführen und Vorlagen und Lesepläne herunterladen.
                                    Aktuell arbeiten wir noch fleißig an der Umsetzung.
                                    Falls Sie wissen möchten, was die Power Hour überhaupt ist, können Sie gerne den folgenden Artikel lesen und direkt mit Ihrer ersten Power Hour starten.</p>
                                <a href="https://www.jesus.de/nachrichten-themen/nachrichten/power-hour-intensivzeit-mit-dem-wort-gottes/" target="_blank">„Power Hour“ – Intensivzeit mit dem Wort Gottes</a>

                                <div style="height: 100px"></div>
                                <h3>Kontaktdaten</h3>
                                <p>Jonas Kern - Am Sperrain 15 - 77704 Oberkirch - kontakt&#64;power-h.de</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 p-0">
                    <div class="coming-soon-image">
                        <img src="assets/img/creative-bg2.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>