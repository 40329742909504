<div class="it">
    <div class="coming-soon-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="coming-soon-content">
                        <div class="logo">
                            <a routerLink="/it"><img src="assets/img/white-logo.png" alt="image"></a>
                        </div>
                        <h1>We are coming soon</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        <div id="timer">
                            <div id="days">
                                {{days}} <span>Days</span>
                            </div>
                            <div id="hours">
                                {{hours}} <span>Hours</span>
                            </div>
                            <div id="minutes">
                                {{minutes}} <span>Minutes</span>
                            </div>
                            <div id="seconds">
                                {{seconds}} <span>Seconds</span>
                            </div>
                        </div>
                        <form class="newsletter-form">
                            <input type="email" class="input-newsletter" placeholder="Enter email address" name="EMAIL">
                            <button type="submit">Notify Me</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>