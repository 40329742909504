import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgencyComponent } from './components/agency/agency.component';
import { AutoServicingComponent } from './components/auto-servicing/auto-servicing.component';
import { CityComponent } from './components/city/city.component';
import { ConstructionComponent } from './components/construction/construction.component';
import { Creative1Component } from './components/creative1/creative1.component';
import { Creative2Component } from './components/creative2/creative2.component';
import { Creative3Component } from './components/creative3/creative3.component';
import { Creative4Component } from './components/creative4/creative4.component';
import { DoctorComponent } from './components/doctor/doctor.component';
import { Event1Component } from './components/event1/event1.component';
import { Event2Component } from './components/event2/event2.component';
import { GymComponent } from './components/gym/gym.component';
import { IcoComponent } from './components/ico/ico.component';
import { ItComponent } from './components/it/it.component';
import { PlumbingComponent } from './components/plumbing/plumbing.component';
import { RealEstateComponent } from './components/real-estate/real-estate.component';
import { Restaurant1Component } from './components/restaurant1/restaurant1.component';
import { Restaurant2Component } from './components/restaurant2/restaurant2.component';
import { Restaurant3Component } from './components/restaurant3/restaurant3.component';
import { SeoComponent } from './components/seo/seo.component';
import { Travel1Component } from './components/travel1/travel1.component';
import { Travel2Component } from './components/travel2/travel2.component';

const routes: Routes = [
  {path: '', component: Creative3Component},
  {path: 'restaurant-1', component: Restaurant1Component},
  {path: 'travel-1', component: Travel1Component},
  {path: 'travel-2', component: Travel2Component},
  {path: 'restaurant-2', component: Restaurant2Component},
  {path: 'restaurant-3', component: Restaurant3Component},
  {path: 'real-estate', component: RealEstateComponent},
  {path: 'creative-1', component: Creative1Component},
  {path: 'doctor', component: DoctorComponent},
  {path: 'it', component: ItComponent},
  {path: 'agency', component: AgencyComponent},
  {path: 'seo', component: SeoComponent},
  {path: 'creative-2', component: Creative2Component},
  {path: 'creative-3', component: Creative3Component},
  {path: 'ico', component: IcoComponent},
  {path: 'gym', component: GymComponent},
  {path: 'event-1', component: Event1Component},
  {path: 'event-2', component: Event2Component},
  {path: 'auto-servicing', component: AutoServicingComponent},
  {path: 'city', component: CityComponent},
  {path: 'plumbing', component: PlumbingComponent},
  {path: 'creative-4', component: Creative4Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }